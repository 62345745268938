$( document ).ready(function() {
    $( '#HomeSlider' ).sliderPro({
        width: '100%',
        fade: true,
        height: 800,
        arrows: false,
        buttons: false,
        waitForLayers: true,
        autoplay: true,
        autoScaleLayers: true
    });

    $('.video-container').fitVids();
    $('.Ribbon blockquote').fitText(1.5, { minFontSize: '28px', maxFontSize: '56px' });
    $('h3.title').fitText(1.5, { minFontSize: '30px', maxFontSize: '50px' });

    AOS.init({
        duration: 1000,
        once: true
    });

    $('.scroll-to').on('click', function(e) {
        e.preventDefault();

        if($(this).attr('href') != undefined) {
            $.scrollTo($(this).attr('href'), 800, {offset:-125});
        }
    });

    PNotify.defaults.styling = 'bootstrap4';
    PNotify.defaults.icons = 'fontawesome5';
});